import { SET_FEATURED_NFT, UPDATE_OTHER_PROPERTY } from "../action/types";

const initialState = {
  featuredNFT: null,
  otherProperty: null, // Example for other properties
};

export default function featuredNFTReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FEATURED_NFT:
      console.log("Updating featured NFT in reducer:", action.payload); // Debug log
      return {
        ...state,
        featuredNFT: action.payload,
      };
    case UPDATE_OTHER_PROPERTY:
      return {
        ...state,
        otherProperty: action.payload,
      };
    default:
      return state;
  }
}
