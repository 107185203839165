const initialState = {
  streamData: [],
  isStream: false,
  title: null,
  goal: null,
  isNew: false,
  goalDescription: null,
  shareUrl: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "START_STREAM":
      return {
        ...state,
        isStream: true,
        streamData: action.payload.streamData,
        goal: action.payload.goal,
        title: action.payload.title,
        isNew: !action.payload.goal ? false : action.payload.isNew,
        goalDescription: action.payload.goalDescription,
        shareUrl: action.payload.shareUrl,
        obs: action.payload.obs,
      };
    case "LEAVE_STREAM":
      return {
        ...state,
        isStream: false,
        streamData: [],
        goal: null,
        title: null,
        isNew: false,
        goalDescription: null,
        shareUrl: null,
      };
    default:
      return state;
  }
};
