import React from "react";
// import * as io from "socket.io-client";

// import { SERVER_URL } from "../constants/api";
// export const socket = io.connect(SERVER_URL, {
//   transports: ["websocket"],
//   reconnection: true,
// });

export const SocketContext = React.createContext();
