import React from 'react';

export default function SkPlay() {
    return (
        <section className="relative z-10 pb-20 pt-[120px]">
            <div className="container">
                <div className="flex flex-wrap">

                    <div className="py-8 px-8 max-w-sm mx-auto bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                        <img className="block mx-auto h-24 rounded-full sm:mx-0 sm:shrink-0 "
                             src="https://tailwindcss.com/img/erin-lindford.jpg"
                             alt="Woman's Face"/>
                        <div className="space-y-2 text-center sm:text-left">
                            <div className="space-y-0.5">
                                <p className="text-lg text-black font-semibold">
                                    Erin Lindford
                                </p>
                                <p className="text-red-500 font-medium">
                                    Product Engineer
                                </p>
                            </div>
                            <button className="px-4 py-1 rounded-full border border-purple-200 text-sm text-purple-600 hover:bg-purple-600 hover:text-white hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                                Message
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}