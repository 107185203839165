import axios from "axios";
import cookieStorage from "./cookie-storage";

class HttpService {
  static async get(url, config) {
    try {
      const response = await axios.get(url, config);
      return response.data;  // Resolve the data from the response
    } catch (error) {
      this.handleError(error);  // Handle the error globally
    }
  }

  static async post(url, body, config) {
    try {
      const response = await axios.post(url, body, config);
      return response.data;  // Resolve the data from the response
    } catch (error) {
      this.handleError(error);  // Handle the error globally
    }
  }

  static async put(url, body, config) {
    try {
      const response = await axios.put(url, body, config);
      return response.data;  // Resolve the data from the response
    } catch (error) {
      this.handleError(error);  // Handle the error globally
    }
  }

    // Adding the DELETE method
    static async delete(url, config) {
      try {
        const response = await axios.delete(url, config);
        return response.data;
      } catch (error) {
        this.handleError(error);
      }
    }

  // Centralized error handler
  static handleError(error) {
    if (!error.response) {
      // No response from server, network issue or server down
      console.error("Network error:", error);
      return Promise.reject("Network error");
    }

    const { status, data } = error.response;

    // Handle JWT expiration or missing token
    if (status === 401 || data.message === "JWT not available") {
      this.clearData();
    }

    // Handle server downtime or specific errors
    if (data.message === "App is temporarily unavailable" || data.code === "SERVER_DOWN") {
      console.log("Server is down.");
      this.serverDownScreen();
    }

    // Reject with the error message or response data
    return Promise.reject(data?.message || "An error occurred");
  }

  static clearData() {
    localStorage.clear();
    cookieStorage.deleteCookie("Authorization");
    setTimeout(() => {
      window.location = "/";
    }, 1000);
  }

  static serverDownScreen() {
    window.location.replace("/maintenance");
  }
}

export default HttpService;
