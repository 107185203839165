import React from "react";
import ReactDOM from "react-dom/client";

import { registerLicense } from "@syncfusion/ej2-base";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-notifications-component/dist/theme.css";
import "react-toastify/dist/ReactToastify.css";

import "./index.scss";
import App from "./App";
import store from "./store";
import featureFlags from "./featureFlags.json"
import { theme } from "./theme/theme";
import { FlagsProvider } from 'react-feature-flags';

registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);

function updateHeadElements() {
    let sikiEnv = process.env.REACT_APP_SIKI_ENV;
    let sikiVersion = process.env.REACT_APP_SIKI_VERSION;
    let bannerText = "SIKI";
    if ( sikiEnv && sikiEnv !== "prod" ) bannerText += " - " + sikiEnv;
    if ( sikiVersion ) bannerText += " - " + sikiVersion;

    console.log();
    console.log( bannerText );
    console.log();

    document.title = bannerText;

    const metaVersion = document.querySelector('meta[name="version"]');
    if ( metaVersion ) metaVersion.setAttribute("content", sikiVersion );
}
updateHeadElements();

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );
root.render(
    // <React.StrictMode>
    <FlagsProvider value={featureFlags}>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <App />
                <ToastContainer />
            </Provider>
        </ThemeProvider>
    </FlagsProvider>
    // </React.StrictMode>
);