export const START_SEEK = 'START_SEEK';
export const END_SEEK = 'END_SEEK';

export const startSeek = (value) => ({
    type: START_SEEK,
    payload: value,
});

export const endSeek = () => ({
    type: END_SEEK,
});
