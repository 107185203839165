const initialState = {
  language: "en",
  languageObject: {},
  availableLanguages: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LNG":
      return {
        ...state,
        languageObject: { ...action.payload.languageObject },
        language: action.payload.language,
        availableLanguages: [...action.payload.availableLanguages],
      };
    default:
      return state;
  }
};
