const initialState = {
  count: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_COUNT":
      // if (state.count >= 3) {
      //   //the count is >= 3 then set count to 0;
      //   return {
      //     ...state,
      //     count: 0,
      //   };
      // } else if (state.count < 3) {
      //the count is < 3 then update the count 0;
      const newState = { ...state, count: action.payload };

      return newState;
    // }

    default:
      return state;
  }
};
