import ApiService from "./../services/api-service";
const initialState = {
  bal: 0,
  hbar: 0,
  matic: 0,
  near: 0,
  lastUpdated: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "BALANCE":
      return { ...state };
    case "SET_BALANCE":
      const { balance, hbarBalance, maticBalance, nearBalance, lastUpdated } =
        action.payload;
      return {
        ...state,
        bal: balance,
        hbar: hbarBalance,
        matic: maticBalance,
        near: nearBalance,
        lastUpdated: lastUpdated,
      };
    // case "CLEAR":
    //   return { ...state, bal: 0 };
    default:
      return state;
  }
};
