import { UPDATE_STREAM_COUNT } from '../action/streamCount';

const initialState = {
    streamCount: 0,
};

const streamCount = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_STREAM_COUNT:
            return {
                ...state,
                streamCount: action.payload,
            };
        default:
            return state;
    }
};

export default streamCount;
