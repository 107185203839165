import axios from "axios";
import { API, SERVER_URL } from '../constants/api';
import cookieStorage from "../services/cookie-storage";
import HttpService from "../services/http-service";
import { serialize } from "./../constants/function";
const config = {
  headers: {
    Authorization: "",
  },
};
export const header = {
  headers: {
    Authorization: cookieStorage.getCookie("Authorization"),
    
    // "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
};
export default class ApiService {
  static async getToken(tempToken) {
    const token = tempToken
      ? tempToken
      : cookieStorage.getCookie("Authorization");

    config.headers.Authorization = token;
    config.headers["Content-Type"] = "application/json";
    config.withCredentials = true;

    // config.headers["Access-Control-Allow-Origin"] = "*";
  }
  //Auth
  static async googleSignInWithToken(payload) {
    return HttpService.post(`${API.googleLogin}`, payload, config);
  }
  static async appleSignInWithToken(payload) {
    return HttpService.post(`${API.appleLogin}`, payload, config);
  }
  static async testUserLogin(payload) {
    await this.getToken();
    return HttpService.post(API.testUserLogin, payload, config);
  }
  static async addUsersToStream(payload) {
    await this.getToken();
    return HttpService.post(API.addUsersToStream, payload, config);
  }
  static async INVITE_COLLABORATOR(payload) {
    await this.getToken();
    return HttpService.post(`${API.INVITE_COLLABORATOR}`, payload, config);
  }
  static async INVITE_ROYALTIES(payload) {
    await this.getToken();
    return HttpService.post(`${API.INVITE_ROYALTIES}`, payload, config);
  }

  static async FUNDINGLINK(body, token) {
    await this.getToken(token);
    return HttpService.post(API.FUNDLINK, body, config);
  }

  static async PAYPALLINK(body, token) {
    await this.getToken(token);
    return HttpService.post(API.PAYPALLINK, body, config);
  }

  static async PAYPALLCAPTURE(payload) {
    await this.getToken();
    const query = serialize(payload);
    return HttpService.get(`${API.PAYPALLCAPTURE}` + query, config);
  }

  static async PAYPALLCANCEL(params) {
    await this.getToken();
    return HttpService.get(`${API.PAYPALLCANCEL}${params}`, config);
  }

  //user
  static async USER_DETAILS(payload) {
    await this.getToken();
    return HttpService.get(`${API.USER_DETAILS}` + payload, config);
  }
  static async FEATURE_USER(params) {
    await this.getToken();
    return HttpService.get(`${API.FEATURE_USER}${params}`, config);
  }
  static async FEATURE_ARTIST(params) {
    await this.getToken();
    return HttpService.get(`${API.FEATURE_ARTIST}${params}`, config);
  }

  static async SEARCH_USER(keyword) {
    await this.getToken();
    return HttpService.get(`${API.SEARCH_USER}` + keyword, config);
  }
  static async UPDATE_USER(payload) {
    await this.getToken();
    config.headers["Content-Type"] = "multipart/form-data";
    return HttpService.post(`${API.UPDATE_USER}`, payload, config);
  }
  static async PROFILE_UPDATE(payload) {
    await this.getToken();
    return HttpService.post(`${API.PROFILE_UPDATE}`, payload, config);
  }
  static async ADD_TOP_MUSIC(payload) {
    await this.getToken();
    return HttpService.post(`${API.ADD_TOP_MUSIC}`, payload, config);
  }
  static async USER_NFT_MUSIC(keyword) {
    await this.getToken();
    const query = serialize(keyword);
    return HttpService.get(`${API.USER_NFT_MUSIC}` + query, config);
  }
  static async USER_NFT_PLAYLIST(keyword) {
    await this.getToken();
    const query = serialize(keyword);
    return HttpService.get(`${API.USER_NFT_PLAYLIST}` + query, config);
  }
  static async USER_PROFILE(token) {
    await this.getToken(token);
    config.headers["Content-Type"] = "multipart/form-data";
    return HttpService.get(`${API.USER_PROFILE}`, config);
  }
  static async FLAG_USER(payload) {
    await this.getToken();
    return HttpService.post(`${API.FLAG_USER}`, payload, config);
  }
  static async ATTRIBUTES(token) {
    await this.getToken(token);

    return HttpService.get(`${API.ATTRIBUTES}`, config);
  }

  //Subscription
  static async UPDATE_SUBSCRIPTION(payload) {
    await this.getToken();
    return HttpService.post(`${API.UPDATE_SUBSCRIPTION}`, payload, config);
  }
  static async GET_SUBSCRIPTIONS(params) {
    await this.getToken();
    return HttpService.get(`${API.GET_SUBSCRIPTIONS}` + params, config);
  }
  static async CREATE_SUBSCRIPTIONS(payload) {
    await this.getToken();
    return HttpService.post(`${API.CREATE_SUBSCRIPTIONS}`, payload, config);
  }
  static async RENEWAL_SUBSCRIPTION_URL(params) {
    await this.getToken();
    return HttpService.post(
      `${API.RENEWAL_SUBSCRIPTION_URL}${params}`,
      { autoRenewal: params },
      config
    );
  }
  static async SUBSCRIBE_ARTIST(payload) {
    await this.getToken();
    return HttpService.post(`${API.SUBSCRIBE_ARTIST}`, payload, config);
  }
  static async UNSUBSCRIBE_ARTIST(payload) {
    await this.getToken();
    return HttpService.post(`${API.UNSUBSCRIBE_ARTIST}`, payload, config);
  }

  static async UNLOCKED_ASSETS(keywords) {
    await this.getToken();
    const query = serialize(keywords);
    return HttpService.get(`${API.UNLOCKED_ASSETS}` + query, config);
  }
  static async PRIVATE_ASSETS(id) {
    await this.getToken();
    return HttpService.get(`${API.PRIVATE_ASSETS}` + id, config);
  }

  //Music

  static async UPLOAD_MUSIC(payload) {
    await this.getToken();
    return HttpService.post(`${API.UPLOAD_MUSIC}`, payload, config);
  }

  static async MY_MUSIC(payload) {
    await this.getToken();
    return HttpService.get(`${API.MY_MUSIC}` + payload, config);
  }
  static async MUSIC_DETAIL(params) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_DETAIL}` + params, config);
  }

  static async FEATURE_MUSIC(payload) {
    await this.getToken();
    return HttpService.get(`${API.FEATURE_MUSIC}${payload}`, config);
  }
  static async SEARCH_MUSIC(keyword) {
    await this.getToken();
    return HttpService.get(`${API.SEARCH_MUSIC}` + keyword, config);
  }
  static async ALL_ARTIST_MUSIC(keywords) {
    const params = "?" + serialize(keywords);
    await this.getToken();
    return HttpService.get(`${API.ALL_ARTIST_MUSIC}` + params, config);
  }
  static async INDIVIDUAL_SONG_TIPPER(params) {
    await this.getToken();
    const query = serialize(params);
    return HttpService.get(`${API.INDIVIDUAL_SONG_TIPPER}` + query, config);
  }

  //Album
  static async MY_ALBUM(body) {
    await this.getToken();
    return HttpService.get(`${API.MY_ALBUM}` + body, config);
  }
  static async CREATE_ALBUM(body) {
    await this.getToken();
    config.headers["Content-Type"] = "multipart/form-data";
    return HttpService.post(`${API.CREATE_ALBUM}`, body, config);
  }
  static async DELETE_ALBUM(params) {
    await this.getToken();
    return HttpService.get(`${API.DELETE_ALBUM}` + params, config);
  }
  static async ALBUM_DETAILS(params) {
    await this.getToken();
    const query = serialize(params);
    return HttpService.get(`${API.ALBUM_DETAILS}` + query, config);
  }

  static async EDIT_ALBUM(body, params) {
    await this.getToken();
    config.headers["Content-Type"] = "multipart/form-data";
    return HttpService.post(`${API.EDIT_ALBUM}${params}`, body, config);
  }
  static async ADD_SONGS_TO_ALBUM(payload) {
    await this.getToken();
    return HttpService.post(`${API.ADD_SONGS_TO_ALBUM}`, payload, config);
  }
  static async DELETE_SONG_FROM_ALBUM(payload) {
    await this.getToken();
    return HttpService.post(`${API.DELETE_SONG_FROM_ALBUM}`, payload, config);
  }
  //PLAYLIST
  static async PLAYLIST(params, isParams) {
    await this.getToken();
    if (isParams) {
      const query = serialize(params);
      return HttpService.get(`${API.MY_PLAYLIST}` + query, config);
    } else {
      return HttpService.get(`${API.PLAYLIST}` + params, config);
    }
  }
  static async PLAYLIST_DETAIL(params) {
    await this.getToken();
    const query = serialize(params);
    return HttpService.get(`${API.PLAYLIST_DETAIL}` + query, config);
  }

  static async CREATE_PLAYLIST(params) {
    await this.getToken();
    return HttpService.post(`${API.CREATE_PLAYLIST}`, params, config);
  }
  static async EDIT_PLAYLIST(id, body) {
    await this.getToken();
    config.headers["Content-Type"] = "multipart/form-data";
    return HttpService.post(
      `${API.EDIT_PLAYLIST}?playlistId=${id}`,
      body,
      config
    );
  }

  static async DELETE_PLAYLIST(params) {
    await this.getToken();
    return HttpService.get(`${API.DELETE_PLAYLIST}` + params, config);
  }

  static async FEATURE_PLAYLIST(params) {
    await this.getToken();
    return HttpService.get(`${API.FEATURE_PLAYLIST}${params}`, config);
  }
  static async SEARCH_PLAYLIST(keyword) {
    await this.getToken();
    return HttpService.get(`${API.SEARCH_PLAYLIST}` + keyword, config);
  }
  static async ASSET_PRIVACY(payload) {
    await this.getToken();
    return HttpService.post(`${API.ASSET_PRIVACY}`, payload, config);
  }
  static async ADD_SONG_TO_PLAYLIST(payload) {
    await this.getToken();
    return HttpService.post(`${API.ADD_SONG_TO_PLAYLIST}`, payload, config);
  }
  static async REMOVE_SONG_FROM_PLAYLIST(payload) {
    await this.getToken();
    return HttpService.post(
      `${API.REMOVE_SONG_FROM_PLAYLIST}`,
      payload,
      config
    );
  }
  static async SAVE_PLAYLIST(payload) {
    await this.getToken();
    return HttpService.post(`${API.SAVE_PLAYLIST}`, payload, config);
  }
  static async GET_PLAYLIST_MUSIC(payload) {
    await this.getToken();
    return HttpService.get(`${API.GET_PLAYLIST_MUSIC}` + payload, config);
  }
  static async PLAYLIST_ALL_(payload) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_ALL_}` + payload, config);
  }
  static async SORT_PLAYLIST_MUSIC(payload) {
    await this.getToken();
    return HttpService.post(`${API.SORT_PLAYLIST_MUSIC}`, payload, config);
  }
  static async TOPTEN_MY_PLAYLIST(params) {
    await this.getToken();
    const query = serialize(params);
    return HttpService.get(`${API.TOPTEN_MY_PLAYLIST}` + query, config);
  }
  static async TOPTEN_BUY_ME_PLAYLIST(params) {
    await this.getToken();
    const query = serialize(params);
    return HttpService.get(`${API.TOPTEN_BUY_ME_PLAYLIST}` + query, config);
  }

  //GENRE
  static async GENRE_LIST() {
    await this.getToken();
    return HttpService.get(`${API.GENRE_LIST}`, config);
  }

  //NFTs
  static async CREATE_PLAYLIST_NFT(body) {
    await this.getToken();
    return HttpService.post(`${API.CREATE_PLAYLIST_NFT}`, body, config);
  }
  static async CREATE_PLAYLIST_NFT_V4(body) {
    await this.getToken();
    return HttpService.post(`${API.CREATE_PLAYLIST_NFT_V4}`, body, config);
  }

  static async CREATE_MUSIC_NFT(body) {
    await this.getToken();
    return HttpService.post(`${API.CREATE_MUSIC_NFT}`, body, config);
  }
  static async CREATE_MUSIC_NFT_V4(body) {
    await this.getToken();
    return HttpService.post(`${API.CREATE_MUSIC_NFT_V4}`, body, config);
  }
  static async CREATE_MUSIC_NFT_V5(body) {
    await this.getToken();

    config.headers["Content-Type"] = "multipart/form-data";
    return HttpService.post(`${API.CREATE_MUSIC_NFT_V5}`, body, config);
  }
  static async MUSIC_NFT() {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_NFT}`, config);
  }
  static async PLAYLIST_NFT() {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_NFT}`, config);
  }
  static async NFT_BY_ID(params) {
    await this.getToken();
    return HttpService.get(`${API.NFT_BY_ID}` + params, config);
  }
  static async NFT_BY_ID_V2(params) {
    await this.getToken();
    return HttpService.get(`${API.NFT_BY_ID_V2}` + params, config);
  }
  static async PLAYLIST_NFT_BY_ID(params) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_NFT_BY_ID}` + params, config);
  }

  static async BID_NFT(body) {
    await this.getToken();
    return HttpService.post(`${API.BID_NFT}`, body, config);
  }
  static async BID_NFT_V2(body) {
    await this.getToken();
    return HttpService.post(`${API.BID_NFT_V2}`, body, config);
  }
  static async BUY_NFT(body) {
    await this.getToken();
    return HttpService.post(`${API.BUY_NFT}`, body, config);
  }
  static async BUY_NFT_V2(body) {
    await this.getToken();
    return HttpService.post(`${API.BUY_NFT_V2}`, body, config);
  }
  static async BUY_MUSIC_NFT(body) {
    await this.getToken();
    return HttpService.post(`${API.BUY_MUSIC_NFT}`, body, config);
  }
  static async SELL_NFT(body) {
    await this.getToken();
    return HttpService.post(`${API.SELL_NFT}`, body, config);
  }
  static async SELL_NFT_V2(body) {
    await this.getToken();
    return HttpService.post(`${API.SELL_NFT_V2}`, body, config);
  }
  static async AUCTION_NFT(body) {
    await this.getToken();
    return HttpService.post(`${API.AUCTION_NFT}`, body, config);
  }
  static async AUCTION_NFT_V2(body) {
    await this.getToken();
    return HttpService.post(`${API.AUCTION_NFT_V2}`, body, config);
  }
  static async NFT_OFFERS(params) {
    await this.getToken();
    return HttpService.get(`${API.NFT_OFFERS}` + params, config);
  }
  static async CANCEL_SELL(body) {
    await this.getToken();
    return HttpService.post(`${API.CANCEL_SELL}`, body, config);
  }
  static async CANCEL_SELL_V2(body) {
    await this.getToken();
    return HttpService.post(`${API.CANCEL_SELL_V2}`, body, config);
  }
  static async CANCEL_AUCTION_NFT(body) {
    await this.getToken();
    return HttpService.post(`${API.CANCEL_AUCTION_NFT}`, body, config);
  }
  static async CANCEL_AUCTION_NFT_V2(body) {
    await this.getToken();
    return HttpService.post(`${API.CANCEL_AUCTION_NFT_V2}`, body, config);
  }

  //GET_NFT_FRACTIONS
  static async GET_NFT_FRACTIONS(params) {
    await this.getToken();
    return HttpService.get(`${API.GET_NFT_FRACTIONS}` + params, config);
  }
  GIFT_NFT_FRACTIONS; //
  static async GIFT_NFT_FRACTIONS(body) {
    await this.getToken();
    return HttpService.post(`${API.GIFT_NFT_FRACTIONS}`, body, config);
  }
  // gift playlist nft
  static async GIFT_PLAYLIST_NFT(body) {
    await this.getToken();
    return HttpService.post(`${API.GIFT_PLAYLIST_NFT}`, body, config);
  }
  //GET all
  static async ALL_NFT(params) {
    await this.getToken();
    const query = serialize(params);
    return HttpService.get(`${API.ALL_NFT}` + query, config);
  }
  //FOLLOW
  static async FOLLOWERLIST(params) {
    await this.getToken();
    return HttpService.get(`${API.FOLLOWERLIST}` + params, config);
  }
  static async FOLLOWINGLIST(params) {
    await this.getToken();
    return HttpService.get(`${API.FOLLOWINGLIST}` + params, config);
  }
  static async UNFOLLOW(body) {
    await this.getToken();
    return HttpService.post(`${API.UNFOLLOW}`, body, config);
  }
  static async FOLLOW(body) {
    await this.getToken();
    return HttpService.post(`${API.FOLLOW}`, body, config);
  }
  //NOTIFICATIONS
  static async NOTIFICATIONS(params) {
    await this.getToken();
    return HttpService.get(`${API.NOTIFICATIONS}` + params, config);
  }
  static async MARK_SEEN(payload) {
    await this.getToken();
    const query = serialize(payload);
    return HttpService.put(`${API.MARK_SEEN}${query}`, {}, config);
  }
  static async MARK_ALL_SEEN(payload) {
    await this.getToken();
    return HttpService.put(`${API.MARK_ALL_SEEN}`, payload, config);
  }
  static async UNREAD() {
    await this.getToken();
    return HttpService.get(`${API.UNREAD}`, config);
  }

  //WALLET
  static async BALANCE() {
    await this.getToken();
    return HttpService.get(`${API.BALANCE}`, config);
  }
  static async TRANSACTIONS(keyword) {
    await this.getToken();
    const query = serialize(keyword);
    return HttpService.get(`${API.TRANSACTIONS}${query}`, config);
  }
  static async TOKEN_LIMIT_REQUEST(payload) {
    await this.getToken();
    return HttpService.post(`${API.TOKEN_LIMIT_REQUEST}`, payload, config);
  }

  //Offboard
  static async OFFBOARD_TRANSFER(body) {
    await this.getToken();
    return HttpService.post(`${API.OFFBOARD_TRANSFER}`, body, config);
  }

  static async OFFBOARD_CASHOUT(body) {
    await this.getToken();
    return HttpService.post(`${API.OFFBOARD_CASHOUT}`, body, config);
  }

  static async OFFBOARD_CASHOUT_LIST(body) {
    await this.getToken();
    return HttpService.post(`${API.OFFBOARD_CASHOUT_LIST}`, body, config);
  }

  static async OFFBOARD_CASHOUT_SEND(body) {
    await this.getToken();
    return HttpService.post(`${API.OFFBOARD_CASHOUT_SEND}`, body, config);
  }

  //Payment
  static async TIP_ARTIST(body) {
    await this.getToken();
    return HttpService.post(`${API.TIP_ARTIST}`, body, config);
  }
  static async TIP_PLAYLIST(body) {
    await this.getToken();
    return HttpService.post(`${API.TIP_PLAYLIST}`, body, config);
  }
  // GAMIFICATION;
  static async CURATOR_DATA(params) {
    await this.getToken();
    return HttpService.get(`${API.CURATOR_DATA}${params}`, config);
  }
  static async CREATOR_DATA(params) {
    await this.getToken();
    return HttpService.get(`${API.CREATOR_DATA}${params}`, config);
  }
  static async TOPTEN_LISTEN_PLAYLIST() {
    await this.getToken();
    return HttpService.get(`${API.TOPTEN_LISTEN_PLAYLIST}`, config);
  }
  static async TOPTEN_TIP_PLAYLIST() {
    await this.getToken();
    return HttpService.get(`${API.TOPTEN_TIP_PLAYLIST}`, config);
  }
  static async TOPTEN_SAVED_PLAYLIST() {
    await this.getToken();
    return HttpService.get(`${API.TOPTEN_SAVED_PLAYLIST}`, config);
  }
  static async PLAYLIST_STATICS(keyword) {
    await this.getToken();
    const query = serialize(keyword);
    return HttpService.get(`${API.PLAYLIST_STATICS}` + query, config);
  }
  //Analytics
  static async MUSIC_ANALYTICS() {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_ANALYTICS}`, config);
  }
  static async LIFEIME_VIEW_ANALYTICS(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFEIME_VIEW_ANALYTICS}${range}`, config);
  }
  static async LIFETIME_TIP_ANALYTICS(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFETIME_TIP_ANALYTICS}${range}`, config);
  }
  static async LIFEIME_STREAM_ANALYTICS(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFEIME_STREAM_ANALYTICS}${range}`, config);
  }
  static async LIFEIME_FOLLOWERS_ANALYTICS(range) {
    await this.getToken();
    return HttpService.get(
      `${API.LIFEIME_FOLLOWERS_ANALYTICS}${range}`,
      config
    );
  }
  static async MUSIC_VIEW_ANALYTICS(params) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_VIEW_ANALYTICS}${params}`, config);
  }
  static async MUSIC_TIP_ANALYTICS(params) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_TIP_ANALYTICS}${params}`, config);
  }
  static async MUSIC_STREAM_ANALYTICS(params) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_STREAM_ANALYTICS}${params}`, config);
  }
  static async MUSIC_FOLLOW_ANALYTICS(params) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_FOLLOW_ANALYTICS}${params}`, config);
  }
  static async AGE_GROUP(params) {
    await this.getToken();
    return HttpService.get(`${API.AGE_GROUP}${params}`, config);
  }
  static async COUNT(params) {
    await this.getToken();
    return HttpService.get(`${API.COUNT}${params}`, config);
  }

  //curator
  static async LIFETIME_TIPS(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFETIME_TIPS}${range}`, config);
  }
  static async LIFETIME_LISTEN(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFETIME_LISTEN}${range}`, config);
  }
  static async LIFETIME_SUBSCRIPTION(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFETIME_SUBSCRIPTION}${range}`, config);
  }
  static async LIFETIME_OWNED(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFETIME_OWNED}${range}`, config);
  }
  static async LIFETIME_PURCHASE(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFETIME_PURCHASE}${range}`, config);
  }
  static async LIFETIME_PLAYLISTS(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFETIME_PLAYLISTS}${range}`, config);
  }
  static async LIFETIME_STREAMS(range) {
    await this.getToken();
    return HttpService.get(`${API.LIFETIME_STREAMS}${range}`, config);
  }
  static async CURATOR_TAB_DATA(range) {
    await this.getToken();
    return HttpService.get(`${API.CURATOR_TAB_DATA}${range}`, config);
  }
  //CREATOR
  static async CREATOR_STREAMS(range) {
    await this.getToken();
    return HttpService.get(`${API.CREATOR_STREAMS}${range}`, config);
  }
  static async SONG_RELEASED(range) {
    await this.getToken();
    return HttpService.get(`${API.SONG_RELEASED}${range}`, config);
  }
  static async NFT_SOLD(range) {
    await this.getToken();
    return HttpService.get(`${API.NFT_SOLD}${range}`, config);
  }
  static async PLAYLIST_ADD(range) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_ADD}${range}`, config);
  }
  static async CREATOR_TAB_DATA(range) {
    await this.getToken();
    return HttpService.get(`${API.CREATOR_TAB_DATA}${range}`, config);
  }
  //HomeScreen

  //MUSIC
  static async MUSIC_ALL(range) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_ALL}`, config);
  }
  static async MUSIC_MOST_LISTEN(range) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_MOST_LISTEN}`, config);
  }
  static async MUSIC_MOST_STREAM_TODAY(range) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_MOST_STREAM_TODAY}`, config);
  }
  static async MUSIC_MOST_STREAM_WEEK(range) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_MOST_STREAM_WEEK}`, config);
  }
  static async MUSIC_NEW_RELEASE(range) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_NEW_RELEASE}`, config);
  }
  static async MUSIC_MOST_TIPPED(range) {
    await this.getToken();
    return HttpService.get(`${API.MUSIC_MOST_TIPPED}`, config);
  }

  //NFT
  static async NFT_ALL(range) {
    await this.getToken();
    return HttpService.get(`${API.NFT_ALL}`, config);
  }
  static async MOSTEXPENSIVE(range) {
    await this.getToken();
    return HttpService.get(`${API.MOSTEXPENSIVE}`, config);
  }
  static async PLAYLISTMOSTEXPENSIVE(range) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLISTMOSTEXPENSIVE}`, config);
  }
  static async LEASTEXPENSIVE(range) {
    await this.getToken();
    return HttpService.get(`${API.LEASTEXPENSIVE}`, config);
  }
  static async PLAYLISTLEASTEXPENSIVE(range) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLISTLEASTEXPENSIVE}`, config);
  }
  static async OWNERPERCENTAGE(range) {
    await this.getToken();
    return HttpService.get(`${API.OWNERPERCENTAGE}`, config);
  }
  static async ENDINGSOONAUCTION(range) {
    await this.getToken();
    return HttpService.get(`${API.ENDINGSOONAUCTION}`, config);
  }
  static async NFT_RANK(param) {
    await this.getToken();
    return HttpService.get(`${API.NFT_RANK}${param}`, config);
  }
  static async ACTIVE_BID(param) {
    await this.getToken();
    return HttpService.get(`${API.ACTIVE_BID}${param}`, config);
  }
  //PLAYLIST
  static async PLAYLIST_ALL(range) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_ALL}`, config);
  }
  static async PLAYLIST_MOST_TIPPED(range) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_MOST_TIPPED}`, config);
  }
  static async PLAYLIST_STREAMED(range) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_STREAMED}`, config);
  }
  static async PLAYLIST_NEW_RELEASE(range) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_NEW_RELEASE}`, config);
  }
  static async PLAYLIST_STREAMED_LASTWEEK(range) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_STREAMED_LASTWEEK}`, config);
  }
  static async PLAYLIST_MOST_STREAMED(range) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_MOST_STREAMED}`, config);
  }
  //STREAM
  static async STREAM_ADD(payload) {
    await this.getToken();
    return HttpService.post(`${API.STREAM_ADD}`, payload, config);
  }

  //Public
  static async CHARGES() {
    await this.getToken();
    return HttpService.get(`${API.CHARGES}`, config);
  }
  //BANNER;
  static async BANNER() {
    await this.getToken();
    return HttpService.get(`${API.BANNER}`, config);
  }

  //Chat:
  static async GET_MESSAGES() {
    await this.getToken();
    return HttpService.get(`${API.GET_MESSAGES}`, config);
  }
  static async GET_PLAYLIST_TOP_FIVE(params) {
    await this.getToken();
    return HttpService.get(`${API.GET_PLAYLIST_TOP_FIVE}${params}`, config);
  }
  static async GET_PLAYLIST_MOST_TIPPED(params) {
    await this.getToken();
    return HttpService.get(`${API.PLAYLIST_TOP_MOST_TIPPED}${params}`, config);
  }
  static async GET_PLAYLIST_MOST_STREAMED(params) {
    await this.getToken();
    return HttpService.get(
      `${API.PLAYLIST_TOP_MOST_STREAMED}${params}`,
      config
    );
  }
  static async GET_TOP_CHATS(params) {
    await this.getToken();
    return HttpService.get(`${API.GET_TOP_CHATS}${params}`, config);
  }
  static async GET_PRIVATE_MESSAGE(params) {
    await this.getToken();
    const param = serialize(params);
    return HttpService.get(`${API.GET_PRIVATE_MESSAGE}${param}`, config);
  }
  static async READ_MESSAGE(params) {
    await this.getToken();
    const param = serialize(params);
    return HttpService.put(`${API.READ_MESSAGE}${param}`, {}, config);
  }

  static async GROUP_CHAT(params) {
    await this.getToken();

    return HttpService.get(`${API.GROUP_CHAT}${params}`, config);
  }
  static async GROUP_CHAT_V2(params) {
    await this.getToken();

    return HttpService.get(`${API.GROUP_CHAT_V2}${params}`, config);
  }

  static async GET_PLAYLIST_MESSAGE(params) {
    await this.getToken();
    const param = serialize(params);
    return HttpService.get(`${API.GET_PLAYLIST_MESSAGE}${param}`, config);
  }
  static async BLOCK_USER_CHAT(body) {
    await this.getToken();
    return HttpService.put(`${API.BLOCK_USER_CHAT}`, body, config);
  }
  static async BLOCKER_USER(params) {
    await this.getToken();
    return HttpService.get(`${API.BLOCKER_USER}${params}`, config);
  }
  static async GET_ROOM_ID(params) {
    await this.getToken();
    return HttpService.get(`${API.GET_ROOM_ID}${params}`, config);
  }

  //Auction House

  static async BID_LIST(params) {
    await this.getToken();
    return HttpService.get(`${API.BID_LIST}${params}`, config);
  }
  static async AUCTION_LIST(params) {
    await this.getToken();
    return HttpService.get(`${API.AUCTION_LIST}${params}`, config);
  }
  static async BID_AND_AUCTIONS() {
    await this.getToken();
    return HttpService.get(`${API.BID_AND_AUCTIONS}`, config);
  }
  static async OFFERS(param) {
    await this.getToken();
    return HttpService.get(`${API.OFFERS}${param}`, config);
  }
  static async ACTIVITY(param) {
    await this.getToken();
    return HttpService.get(`${API.ACTIVITY}${param}`, config);
  }
  static async OFFERS_PLAYLIST(param) {
    await this.getToken();
    return HttpService.get(`${API.OFFERS_PLAYLIST}${param}`, config);
  }
  static async ACTIVITY_PLAYLIST(param) {
    await this.getToken();
    return HttpService.get(`${API.ACTIVITY_PLAYLIST}${param}`, config);
  }

  //OFFERS
  static async OFFERS_SALE(param) {
    await this.getToken();
    return HttpService.get(`${API.OFFERS_SALE}${param}`, config);
  }
  static async OFFERS_AUCTION(param) {
    await this.getToken();
    return HttpService.get(`${API.OFFERS_AUCTION}${param}`, config);
  }

  //Streaming
  static async TOP_TIPPERS(param) {
    await this.getToken();
    return HttpService.get(`${API.TOP_TIPPERS}${param}`, config);
  }
  static async GOAL(param) {
    await this.getToken();
    return HttpService.get(`${API.GOAL}${param}`, config);
  }
  static async LIVESTREAM(param) {
    await this.getToken();
    return HttpService.get(`${API.LIVESTREAM}${param ? param : ""}`, config);
  }
  static async LAYOUT(param) {
    await this.getToken();
    return HttpService.post(`${API.LAYOUT}${param}`, {}, config);
  }

  //MATIC

  static async CREATE_MATIC(param) {
    await this.getToken();
    return HttpService.post(`${API.CREATE_MATIC}${param}`, {}, config);
  }

  //VIDEO

  static async UPLOAD_VIDEO(payload) {
    await this.getToken();
    return HttpService.post(`${API.VIDEO}`, payload, config);
  }

// Update video with studioId, videoId, and formData
static async UPDATEVIDEO({ studioId, videoId, formData }) {
  await this.getToken();
  
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: cookieStorage.getCookie("Authorization"),
    },
  };

  const updateUrl = `${API.UPDATEVIDEO.replace(':studioId', studioId).replace(':videoId', videoId)}`;
  return HttpService.put(updateUrl, formData, config);
}

// Delete video with studioId and videoId
static async deleteUserVideo(studioId, videoId) {
  await this.getToken();
  
  const config = {
    headers: {
      Authorization: cookieStorage.getCookie("Authorization"),
    },
  };

  const deleteUrl = `${API.DELETE_VIDEO.replace(':studioId', studioId).replace(':videoId', videoId)}`;
  return HttpService.delete(deleteUrl, config);
}

  

  static async VIDEO_USER(studioId) {
    await this.getToken();
    
    // Dynamically replace `:studioId` with the actual `studioId`
    const getvidUrl = `${API.VIDEO_USER.replace(':studioId', studioId)}`;
  
    // Fetch the videos
    return HttpService.get(getvidUrl, config);
  }
  

static async UPVID(studioId, formData) {
  await this.getToken(); 
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: cookieStorage.getCookie("Authorization"),
    }
  };
  const uploadUrl = `${API.UPVID.replace(':studioId', studioId)}`;

  return HttpService.post(uploadUrl, formData, config);
}


static async deleteUserVideo(studioId, videoId) {
  await this.getToken(); 
  const config = {
 
    headers: {
      Authorization: cookieStorage.getCookie("Authorization"),
  }
};
  const deleteUrl = `${API.DELETE_VIDEO.replace(':studioId', studioId).replace(':videoId', videoId)}`;
  return HttpService.delete(deleteUrl, config);
}



  static async GET_VIDEOS(params) {
    await this.getToken();
    return HttpService.get(`${API.VIDEO}${params}`, config);
  }
  static async D_VIDEOS(params) {
    await this.getToken();
    return HttpService.get(`${API.DOWNLOAD_RECORDING}${params}`, config);
  }

  //Recordings
  static async RECORDINGS(params) {
    await this.getToken();
    return HttpService.get(`${API.RECORDINGS}${params}`, config);
  }

  static async deleteRecording(recordingId) {
    await this.getToken();
  
    const deleteUrl = `${API.DELETE_RECORDING.replace(':recordingId', recordingId)}`;
    console.log("Delete Recording URL:", deleteUrl);
  
    return HttpService.delete(deleteUrl, config);
  }
// ApiService.js

static async updateRecording(recordingId, formData) {
  await this.getToken();

  // Check if `recordingId` is provided
  if (!recordingId) {
    throw new Error("Recording ID is missing for update.");
  }

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: cookieStorage.getCookie("Authorization"),
    },
  };

  // Replace `:recordingId` with the actual recording ID
  const updateUrl = `${API.UPDATE_RECORDING.replace(':recordingId', recordingId)}`;
  console.log("Update Recording URL:", updateUrl);

  // Make sure to use `FormData` for the request payload
  return HttpService.put(updateUrl, formData, config);
}


  //language
  static async LANGUAGE(params) {
    await this.getToken();
    return HttpService.get(`${API.LANGUAGE}${params}`, config);
  }
  //Update gift
  static async updatGiftStatus(payload) {
    await this.getToken();
    return HttpService.post(`${API.UPDATE_GIFT_STATUS}`, payload, config);
  }
  //Add count
  static async ADD_COUNT() {
    await this.getToken();
    return HttpService.get(`${API.ADD_COUNT}`, config);
  }
  //Add
  static async ADD(params) {
    await this.getToken();
    return HttpService.get(`${API.ADD}${params}`, config);
  }

  //Trending music
  static async TRENDING(params) {
    await this.getToken();
    return HttpService.get(`${API.TRENDING}${params}`, config);
  }

  static async TOP_ALBUM_MUSIC_STUDIO_LIST(keyword) {
    await this.getToken();
    const query = serialize(keyword);
    return HttpService.get(`${API.TOP_LIST}` + query, config);
  }

  static async MUSIC_LIST(keyword) {
    await this.getToken();
    const query = serialize(keyword);
    return HttpService.get(`${API.MUSIC_LIST}` + query, config);
  }

  // Artist Search
  static async SEARCH_ARTIST(keyword) {
    await this.getToken();
    return HttpService.get(`${API.SEARCH_ARTIST}` + keyword, config);
  }

  // Elastic Search
  static async SEARCH(keyword) {
    await this.getToken();
    return HttpService.get(
      `${API.SEARCH}` + encodeURIComponent(keyword),
      config
    );
  }

  // schedule livestream
  static async SCHEDULE_LIVESTREAM(payload) {
    await this.getToken();
    return HttpService.post(`${API.SCHEDULE_LIVESTREAM}`, payload, config);
  }

  // schedule livestream cancel
  static async SCHEDULE_LIVESTREAM_CANCEL(keyword) {
    await this.getToken();
    return HttpService.post(`${API.SCHEDULE_LIVESTREAM_CANCEL}`, payload, config);
  }

  // schedule livestreams
  static async SCHEDULE_LIVESTREAMS(params) {
    await this.getToken();
    const query = serialize(params);
    return HttpService.get(`${API.SCHEDULE_LIVESTREAMS}` + query, config);
  }

  // schedule livestreams date
  static async SCHEDULE_LIVESTREAMS_DATE(params) {
    await this.getToken();
    const query = serialize(params);
    return HttpService.get(`${API.SCHEDULE_LIVESTREAMS_DATE}` + query, config);
  }

  // schedule livestreams user
  static async SCHEDULE_LIVESTREAMS_USER(params) {
    await this.getToken();
    const query = serialize(params);
    return HttpService.get(`${API.SCHEDULE_LIVESTREAMS_USER}` + query, config);
  }

  // Dynamic Link
  static async CREATE_DYNAMIC_LINK(payload) {
    await this.getToken();
    return HttpService.post(`${API.CREATE_DYNAMIC_LINK}`, payload, config);
  }
}
