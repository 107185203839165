import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { Images } from "../../assets/common/index";
function Loader({ _class, wait, message, oldLoader }) {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Box className={_class ? _class : "__loader"}>
      {wait ? (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            padding: "10px",
            borderRadius: "6px",
            // background: "#1E1E1E",
            background: "#171515",
          }}
        >
          <CircularProgress />
          {wait ? (
            <Typography className="fs-17 fw-b" sx={{ padding: "20px" }}>
              {message ? message : "Please wait..."}
            </Typography>
          ) : null}
        </Stack>
      ) : (
        // !oldLoader ? (
        //   <Stack direction="row" alignItems="center">
        //     <img style={{ width: "150px" }} src={Images.loader} alt="loading" />
        //   </Stack>
        // )

        <Stack direction="row" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
    </Box>
  );
}

export default Loader;
