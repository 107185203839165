import { toast } from "react-toastify";
import "./index.scss";
const notificationConfig = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "dark"
};
const notifier = {
  default: (message) => toast(message, notificationConfig),
  success: (message) => toast.success(message, notificationConfig),
  error: (message) => toast.error(message, notificationConfig),
  warning: (message) => toast.warn(message, notificationConfig),
  info: (message) => toast.info(message, notificationConfig),
};

export default notifier;
