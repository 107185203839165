import {
  checkIsOwner,
  getStreamingBalance,
  getUserId,
  putStreamRecord,
} from "./../constants/function";
import notifier from "./../components/Notifier";
const initialState = {
  songs: [],
  index: -1,
  prevIndex: -1,
  all: false,
};

export default (state = initialState, action) => {
  let streamCount = getStreamingBalance();
  switch (action.type) {
    case "SONGS":
      return { ...state };
    case "ADD_SONG":
      if (streamCount === "undefined" || streamCount <= 0) {
        notifier.warning("To continue enjoying music, upgrade now!");
        return state;
      }
      const i = findMusic(state.songs, action.payload["_id"]);
      if (state.songs.length > 0 && i >= 0) {
        action["payload"]["playListId"] = action.playListId;
        action["payload"]["albumId"] = action.albumId;
        const temp = { ...state, songs: [...state.songs] };
        temp["index"] = i;
        return temp;
        // return state;
      } else {
        action["payload"]["playListId"] = action.playListId;
        action["payload"]["albumId"] = action.albumId;
        const temp = { ...state, songs: [...state.songs, action.payload] };
        temp["index"] =
          action["isQueue"] &&
          state["index"] !== -1 &&
          !temp["songs"][state["index"]]["end"]
            ? state["index"]
            : temp["songs"].length - 1;
        // console.log(`state["index"]    ${state["index"]}  ${temp["index"]}`);
        temp["all"] = action["isQueue"] ? true : false;
        // console.log(temp);
        return temp;
      }

    case "ADD_SONGS":
      // when user click on other song to
      // play then previous song current time sholuld be 0
      // and kinisie stop should emit
      if (
        state.index >= 0 &&
        state.index !== action.payload.index &&
        state.songs[state.index]?.track
      ) {
        const userId = getUserId();
        const payload = {
          eventType: "stop",
          timestamp: new Date().getTime(),
          userId: userId,
          musicId: state.songs[state.index]._id,
          playlistId: state.songs[state.index]?.playListId,
          livestreamId: null,
          songDetails: state.songs[state.index],
          timeInSong: state.songs[state.index]?.track?.currentTime,
          associationId: new Date().getTime(),
          sessionId: "sessionId",
          albumId: state.songs[state.index]?.albumId,
        };
        putStreamRecord(payload);
        state.songs[state.index].track.currentTime = 0;
      }
      if (streamCount === "undefined" || streamCount <= 0) {
        notifier.warning("To continue enjoying music, upgrade now!");
        return state;
      }
      const temp = [];
      let foundIndex = -1;
      action.payload.forEach((m, i) => {
        m["playListId"] = action.playListId ? action.playListId : null;
        m["albumId"] = action.albumId ? action.albumId : null;
        foundIndex = findMusic(
          state.songs,
          action.payload[action.index]["_id"]
        );
        if (
          !checkIsOwner(m) &&
          !m["isSubscribed"] &&
          typeof m["uploader"] === "object" &&
          !m["uploader"]["isSubscribed"] &&
          m["private"]
        ) {
          //do nothing if song is not owned py loggedin user and not subscribed and song is private
        } else if (
          action.index != null &&
          action.index >= 0 &&
          foundIndex >= 0
        ) {
          //do nothing if song is not owned py loggedin user and not subscribed and song is private
        } else {
          temp.push(m);
        }
      });
      return {
        ...state,
        songs: [...state.songs, ...[...temp]],
        index: action.isMore
          ? state.index
          : state.index === -1 && !action.index
          ? state.index + 1
          : foundIndex >= 0
          ? foundIndex
          : state.songs.length > 0 && temp.length > 0
          ? state.songs.length + action.index
          : state.index === -1 && action.index >= 0
          ? action.index
          : state.songs.length,
        all: true,
      };
    case "ADD_SONG_INDEX":
      return {
        ...state,
        songs: [...state.songs],
        prevIndex: state["index"],
        index: action.payload,
      };
    case "REMOVE": {
      const tempState = state.songs;
      if (tempState[action.payload]["track"]) {
        tempState[action.payload]["track"].pause();
      }
      tempState.splice(action.payload, 1);
      return {
        ...state,
        songs: [...tempState],
        index: action.payload < state.index ? state.index - 1 : state.index,
      };
    }

    case "CLEAR":
      if (state.songs.length > 0) {
        state.songs[state.index]["track"].pause();
      }
      return { ...state, songs: [], index: -1, all: false };
    default:
      return state;
  }
};
export const findMusic = (musics, musicId) => {
  for (let index = 0; index < musics.length; index++) {
    if (musics[index]["_id"] === musicId) return index;
  }
  return -1;
};
