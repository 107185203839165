import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import Loader from "./components/Loader";

const Pages = React.lazy(() => import("./pages/"));

function RouterOutlet(props) {
  return (
    <Suspense fallback={<Loader />}>
            <Route path="/" render={(props) => <Pages {...props} />} />
    </Suspense>
  );
}

export default RouterOutlet;
