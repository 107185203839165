import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import * as io from "socket.io-client";

import "./App.scss";
import { SERVER_URL } from "./constants/api";
import ApiService from "./services/api-service";
import { setLanguage } from "./action/language";
import common_en from "./translations/en/common.json";
import RouterOutlet from "./router-outlet";
import { UserContext } from "./context/userContext";
import { SocketContext } from "./context/socket";
import cookieStorage from "./services/cookie-storage";
import SkPlay from './SkPlay';

function App() {
  const [userData, setUser] = useState([]);
  const { languageObject, language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const token = cookieStorage.getCookie("Authorization");

  const [Socket, setSocket] = useState();
  const value = useMemo(() => ({ userData, setUser }), [userData, setUser]);
  const socket = useMemo(() => ({ Socket, setSocket }), [Socket, setSocket]);

  useEffect(() => {
    let data = localStorage.getItem("userData");
    if (data) {
      setUser(JSON.parse(data).userData);
      getI18nData();
    }
  }, []);

  const getI18nData = async () => {
    try {
      const lng = cookieStorage.getCookie("language");
      const response = await ApiService.LANGUAGE(
        `language=${lng ? lng : "en"}&class=web`
      );
      const { availableLanguages, languageObject, selectedLanguage, token } =
        response.result;
      if (token) cookieStorage.createCookie("Authorization", token, 1);

      dispatch(
        setLanguage({
          language: selectedLanguage,
          languageObject: languageObject,
          availableLanguages: availableLanguages,
        })
      );
      cookieStorage.createCookie("language", selectedLanguage);
    } catch (error) {
      i18next.init({
        interpolation: { escapeValue: false },
        lng: "en",
        resources: {
          en: {
            common: common_en,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (userData && !window.location.href.includes("/app/LiveVideoStreaming/")) {
      setSocket(
        io.connect(SERVER_URL, {
          auth: { JWT: token },
          transports: ["websocket"],
          reconnection: true,
        })
      );
    }
  }, [userData, token]);

  useEffect(() => {
    cookieStorage.createCookie("language", language ? language : "en");
    const common = Object.keys(languageObject).length === 0 ? common_en : languageObject;

    i18next.init({
      interpolation: { escapeValue: false },
      lng: language ? language : "en",
      resources: {
        [language]: {
          common: common,
        },
      },
    });
  }, [languageObject, language]);

  return (
    <I18nextProvider i18n={i18next}>
      <SocketContext.Provider value={socket}>
        <UserContext.Provider value={value}>
          <BrowserRouter>
              <RouterOutlet />
          </BrowserRouter>
        </UserContext.Provider>
      </SocketContext.Provider>
    </I18nextProvider>
  );
}

export default App;
