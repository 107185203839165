import { START_SEEK, END_SEEK } from '../action/seek';

const initialState = {
    started: false,
    value: null,
};

const seek = (state = initialState, action) => {
    switch (action.type) {
        case START_SEEK:
            return {
                ...state,
                started: true,
                value: action.payload,
            };
        case END_SEEK:
            return {
                ...state,
                started: false,
                value: null,
            };
        default:
            return state;
    }
};

export default seek;
