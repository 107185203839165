const initialState = {
  show: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "HIDE_CHAT":
      const s = { ...state, show: action.payload };
      return s;
    default:
      return { ...state };
  }
};
